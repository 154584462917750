import {
  ActionIcon,
  Alert,
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Group,
  Image,
  List,
  Modal,
  Paper,
  Select,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useModals } from "@mantine/modals";
import { RichTextEditor } from "@mantine/tiptap";
import {
  IconChevronDown,
  IconChevronUp,
  IconCornerUpLeft,
  IconExternalLink,
  IconEyeOff,
  IconFilePlus,
  IconLink,
  IconLogout,
  IconNotes,
  IconUnlink,
  IconX,
  IconZoomIn,
} from "@tabler/icons";
import { format } from "date-fns";
import { enGB, fi, sv } from "date-fns/locale";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLog } from "react-lazylog";
import {
  useGithubAuthMutation,
  useRevokeGithubAuth,
} from "../../../../api/admin/useGithubOAuth";
import { ErrorResponse } from "../../../../helpers/requests.helper";
import {
  fiLocale,
  getFreshDeskUrl,
  svLocale,
} from "../../../../helpers/universal.helper";
import { ORGANIZATIONS_PATH, USERS_PATH } from "../../../../navigation/Routes";
import { Course } from "../../../../types/course.types";
import { Organization } from "../../../../types/organization.types";
import { School } from "../../../../types/school.types";
import { User } from "../../../../types/user.types";
import CourseCard from "../../../global/Cards/Course/CourseCard";
import {
  InitialsBadge,
  SkillsterBadge,
} from "../../../global/Misc/Misc.components";
import { PriorityCode, TicketStatusCode } from "../Tickets/Tickets.api";
import githubIcon from "./assets/github-mark-white.png";
import TextEditor from "./TextEditor";
import {
  Issue,
  Message,
  MessageTypeNotePrivate,
  MessageTypeReply,
  Repo,
  SyllabusExercise,
  SyllabusLesson,
  Ticket,
  useCreateIssue,
  useCreateMessage,
  useLinkIssue,
  useListRepos,
  useUnlinkIssue,
} from "./TicketDetails.api";
import {
  buildResponsePreviousThread,
  findReplyEmail,
  parseMessageBody,
} from "./TicketDetails.helpers";
import {
  DescriptionWrapper,
  LazyLogWrapper,
} from "./TicketDetails.styled.components";

interface BugLogProps {
  console?: string;
}

export const BugLog = (props: BugLogProps) => {
  const [open, setOpen] = useState(false);
  if (!props.console) return null;

  return (
    <>
      <Modal
        opened={open ?? false}
        onClose={() => setOpen(false)}
        withCloseButton={false}
        size={"80vw"}
        centered
        styles={{
          modal: { padding: 0 },
        }}
      >
        <div style={{ position: "relative", height: "80vh", padding: 0 }}>
          <LazyLog
            selectableLines
            caseInsensitive={true}
            enableSearch
            text={props.console}
          />
        </div>
      </Modal>
      <LazyLogWrapper>
        <div className="header">
          <Title order={2}>Console</Title>
          <Button
            onClick={() => setOpen(true)}
            variant="light"
            leftIcon={<IconZoomIn width={18} />}
          >
            Förstora
          </Button>
        </div>
        <Space h="md" />
        <LazyLog
          selectableLines
          caseInsensitive={true}
          enableSearch
          height={700}
          text={props.console}
        />
      </LazyLogWrapper>
    </>
  );
};

interface SystemInfoDetails {
  title: string;
  content: string[];
}

interface SystemInfoProps {
  systemInfo?: string;
}

export const SystemInfo = (props: SystemInfoProps) => {
  if (!props.systemInfo) return null;

  const parsed = props.systemInfo
    .split("\n\n")
    .map((line: string) => line.replaceAll("----", ""));
  parsed.splice(-1);

  const info: SystemInfoDetails[] = parsed.map((line: string) => {
    const titleLength = line.indexOf("\n");
    const title = line.substring(0, titleLength);
    const content = line.substring(titleLength + 3, line.length).split("\n  ");
    return { title, content };
  });

  return (
    <Stack>
      <Title order={2}>Systeminformation</Title>
      <SimpleGrid
        breakpoints={[
          { minWidth: "sm", cols: 2 },
          { minWidth: "lg", cols: 3 },
        ]}
      >
        {info.map((info: SystemInfoDetails, index: number) => (
          <Paper p="md" shadow="sm" key={index}>
            <Title order={5}>{info.title}</Title>
            <List mt="xs">
              {info.content.map((content: string, indexContent: number) => (
                <List.Item key={indexContent}>{content}</List.Item>
              ))}
            </List>
          </Paper>
        ))}
      </SimpleGrid>
    </Stack>
  );
};

interface ScreenshotProps {
  screenshot: string;
}

export const Screenshot = (props: ScreenshotProps) => {
  const modals = useModals();
  const openModal = () =>
    modals.openModal({
      withCloseButton: false,
      size: "100%",
      padding: "sm",
      children: (
        <Center>
          {props.screenshot ? (
            <Image
              radius="md"
              src={`data:image/jpeg;base64,${props.screenshot}`}
              alt="Screenshot"
            />
          ) : (
            <Skeleton height={607} radius="sm" />
          )}
        </Center>
      ),
      centered: true,
    });
  return (
    <Stack>
      <Title order={2}>Skärmdump</Title>
      {props.screenshot ? (
        <Image
          radius="md"
          src={`data:image/jpeg;base64,${props.screenshot}`}
          alt="Screenshot"
          onClick={openModal}
          withPlaceholder
          sx={{
            cursor: "pointer",
            transition: "transform 80ms ease-in-out",
            ":hover": {
              transform: "scale(1.003)",
              opacity: 0.9,
            },
          }}
        />
      ) : (
        <Skeleton height={607} radius="sm" />
      )}
    </Stack>
  );
};

interface DescriptionProps {
  ticket?: Ticket;
}

export const Description = (props: DescriptionProps) => {
  return (
    <Stack>
      <DescriptionWrapper>
        <div className="inner">
          <section className="bug-description">
            {props.ticket?.User && props.ticket?.CreationTime ? (
              <div className="section">
                <InitialsBadge
                  inverted
                  className="badge"
                  user={props.ticket?.User}
                />
                {props.ticket?.Description === "" ? (
                  <div className="bubble dots">
                    <div className="text">
                      <div className="dot-flashing" />
                    </div>
                  </div>
                ) : (
                  <div className="bubble">
                    <div className="text">
                      <div className="name">
                        {props.ticket?.User.FirstName}{" "}
                        {props.ticket?.User.LastName}
                      </div>
                      <div
                        className="message-content"
                        dangerouslySetInnerHTML={{
                          __html: props.ticket?.DescriptionHtml ?? "",
                        }}
                      />
                      <div className="date">
                        {format(new Date(props.ticket?.CreationTime), "d MMM", {
                          locale: svLocale() ? sv : fiLocale() ? fi : enGB,
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {props.ticket?.User && props.ticket?.Conversation
              ? props.ticket?.Conversation?.map(
                  (conversation: Message, index: number) => (
                    <div
                      className={`section ${
                        conversation.Agent.Internal
                          ? conversation.Private
                            ? "skillster-private"
                            : "skillster"
                          : ""
                      }`}
                      key={index}
                    >
                      {!conversation.Agent.Internal && props.ticket?.User ? (
                        <InitialsBadge
                          inverted
                          className="badge"
                          user={props.ticket?.User}
                        />
                      ) : null}
                      <div className="bubble">
                        <div className="text">
                          <MessageContent
                            message={conversation}
                            user={props.ticket?.User}
                          />
                        </div>
                      </div>
                      {conversation.Agent.Internal && <SkillsterBadge />}
                    </div>
                  )
                )
              : null}
          </section>
        </div>
      </DescriptionWrapper>
    </Stack>
  );
};

interface MessageContentProps {
  message: Message;
  user?: User;
}

const MessageContent = (props: MessageContentProps) => {
  const content = parseMessageBody(props.message.BodyHtml);
  const [expanded, setExpanded] = useState(false);

  //`${props.ticket?.User?.FirstName} ${props.ticket?.User?.LastName}`
  const userName = props.message.Agent.Internal
    ? props.message.Agent.Name
    : props.user?.Email === props.message.Agent.Email
    ? `${props.user?.FirstName} ${props.user?.LastName}`
    : props.message.Agent.Email;

  return (
    <>
      <div className="name">
        <Group align="center" spacing="xs">
          {props.message.Private ? <IconEyeOff size={14} /> : undefined}
          <div>{userName}</div>
        </Group>
      </div>
      <div
        className="message-content"
        dangerouslySetInnerHTML={{
          __html: (expanded ? content.full_html : content.html) ?? "",
        }}
      />
      <Flex justify={content.full_html ? "space-between" : "flex-end"}>
        {content.full_html && (
          <Group
            className="message-more"
            spacing={6}
            onClick={() => setExpanded(!expanded)}
          >
            <Text>{expanded ? "Mindre" : "Mer"}</Text>
            {expanded ? (
              <IconChevronUp size={14}></IconChevronUp>
            ) : (
              <IconChevronDown size={14}></IconChevronDown>
            )}
          </Group>
        )}
        <div className="date">
          {format(new Date(props.message.CreationTime), "d MMM", {
            locale: svLocale() ? sv : fiLocale() ? fi : enGB,
          })}
        </div>
      </Flex>
    </>
  );
};

interface TicketInformationProps {
  type?: string;
  bugNumber?: number;
  appVersion?: string;
  respondTo?: string;
  status?: number;
  onStatusChanged: (val: string) => void;
  priority?: number;
  onPriorityChanged: (val: string) => void;
  loading: boolean;
  isUpdateError: boolean;
}

export const TicketInformation = (props: TicketInformationProps) => {
  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack>
        <Title order={5}>Information</Title>
        <Stack spacing="xs">
          <Text
            sx={(theme) => ({ color: theme.colors.bluegray[5] })}
            size="sm"
            weight="bold"
          >
            Typ
          </Text>
          <Text>
            {props.type || (
              <Skeleton height={12} width={180} mt={6} radius="sm" />
            )}
          </Text>
        </Stack>
        {props.bugNumber && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Buggnummer
            </Text>
            <Text>{props.bugNumber}</Text>
          </Stack>
        )}
        {props.appVersion && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Version
            </Text>
            <Text>{props.appVersion}</Text>
          </Stack>
        )}
        {props.respondTo && (
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Svara till
            </Text>
            <Text>{props.respondTo}</Text>
          </Stack>
        )}
        <Select
          label="Status"
          value={props.status?.toString() ?? ""}
          onChange={props.onStatusChanged}
          disabled={props.loading}
          data={[
            { label: "Open", value: TicketStatusCode.Open.toString() },
            { label: "Pending", value: TicketStatusCode.Pending.toString() },
            { label: "Resolved", value: TicketStatusCode.Resolved.toString() },
            { label: "Closed", value: TicketStatusCode.Closed.toString() },
          ]}
        />
        <Select
          label="Prioritet"
          value={props.priority?.toString() ?? ""}
          onChange={props.onPriorityChanged}
          disabled={props.loading}
          data={[
            { label: "Low", value: PriorityCode.Low.toString() },
            { label: "Medium", value: PriorityCode.Medium.toString() },
            { label: "High", value: PriorityCode.High.toString() },
            { label: "Urgent", value: PriorityCode.Urgent.toString() },
          ]}
        />
        {props.isUpdateError ? (
          <Alert color="red">Något gick fel vid uppdateringen</Alert>
        ) : undefined}
      </Stack>
    </Paper>
  );
};

interface UserDetailsProps {
  user?: User;
  organization?: Organization;
  school?: School;
}

export const UserDetails = (props: UserDetailsProps) => {
  return (
    <Stack>
      <Paper
        p="md"
        sx={(theme) => ({
          backgroundColor: theme.colors.bluegray[0],
        })}
      >
        <Stack>
          <Title order={5}>Rapportör</Title>
          <Box
            component="a"
            href={`${USERS_PATH}/${props.user?.ID}`}
            target="_blank"
            rel="noreferrer"
            sx={(theme) => ({
              backgroundColor: "white",
              padding: theme.spacing.sm,
              borderRadius: theme.radius.sm,
              ":hover": {
                boxShadow: [
                  `0 0 0 2px ${theme.colors.blue[5]}, ${theme.shadows.lg}`,
                ],
              },
            })}
          >
            {props.user ? (
              <Group spacing="xs">
                <InitialsBadge user={props.user} />
                <Title order={5}>
                  {props.user.FirstName} {props.user.LastName}
                </Title>
              </Group>
            ) : (
              <Group spacing="xs">
                <Skeleton
                  height={38}
                  style={{ flexShrink: 0 }}
                  mr={10}
                  circle
                  radius="xl"
                />
                <Skeleton height={12} radius="xl" />
              </Group>
            )}
          </Box>
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Organisation
            </Text>
            <Group align="center" position="apart">
              {props.organization ? (
                <>
                  <Anchor
                    href={`${ORGANIZATIONS_PATH}/${props.organization.ID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.organization.Name}
                  </Anchor>
                  <Tooltip label="Organisations-ID">
                    <Badge>{props.organization.ID}</Badge>
                  </Tooltip>
                </>
              ) : (
                <Skeleton height={12} width={180} mt={6} radius="sm" />
              )}
            </Group>
          </Stack>
          <Stack spacing="xs">
            <Text
              sx={(theme) => ({ color: theme.colors.bluegray[5] })}
              size="sm"
              weight="bold"
            >
              Skola
            </Text>
            <Group align="center" position="apart">
              {props.school ? (
                <>
                  <Anchor
                    href={`${ORGANIZATIONS_PATH}/${props.organization?.ID}/${props.school.ID}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.school.Name}
                  </Anchor>
                  <Tooltip label="Skol-ID">
                    <Badge>{props.school.ID}</Badge>
                  </Tooltip>
                </>
              ) : (
                <Skeleton height={12} width={160} mt={6} radius="sm" />
              )}
            </Group>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

interface CreateIssueModalProps {
  repos: Repo[];
  ticket: Ticket;
  onSuccess: () => void;
  onClose: () => void;
}

const CreateIssueModal = (props: CreateIssueModalProps) => {
  const { t } = useTranslation();
  const description = `Länk: 
${window.location.href}

Beskrivning:
${props.ticket.Description} 
`;

  const [issue, setIssue] = useState({
    TicketID: props.ticket.ID,
    Repo: props.repos[0].Name,
    Title: `Ärende #${props.ticket.ID}`,
    Description: description,
  });

  const createIssueMutation = useCreateIssue(() => {
    props.onSuccess();
    props.onClose();
  });

  const updateIssue = (val: any) => {
    setIssue((prev) => {
      const newVal = { ...prev, ...val };
      return newVal;
    });
  };

  const createIssue = () => {
    createIssueMutation.mutate(issue);
  };

  return (
    <Stack>
      <Select
        label="Repo"
        data={props.repos.map((x) => x.Name)}
        value={issue.Repo}
        onChange={(event: any) => {
          updateIssue({ Repo: event.target.value });
        }}
      ></Select>
      <TextInput
        label="Titel"
        value={issue.Title}
        placeholder="Ange titel"
        autoFocus
        rightSection={
          issue.Title && (
            <CloseButton
              size={16}
              variant="transparent"
              onClick={() => updateIssue({ Title: "" })}
            />
          )
        }
        onChange={(event: any) => {
          updateIssue({ Title: event.target.value });
        }}
      ></TextInput>
      <Textarea
        label="Beskrivning"
        placeholder="Lägg till beskrivning"
        rightSection={
          issue.Description && (
            <Box style={{ alignItems: "flex-start", marginTop: 12 }}>
              <CloseButton
                size={16}
                variant="transparent"
                onClick={() => updateIssue({ Description: "" })}
              />
            </Box>
          )
        }
        sx={{
          ".mantine-Textarea-rightSection": {
            alignItems: "flex-start", // Align items to the start (top)
          },
        }}
        value={issue.Description}
        minRows={10}
        onChange={(event: any) => {
          updateIssue({ Description: event.target.value });
        }}
      ></Textarea>
      <Group position="right">
        <Button variant="light" onClick={props.onClose}>
          Avbryt
        </Button>
        <Button onClick={createIssue} loading={createIssueMutation.isLoading}>
          Skapa
        </Button>
      </Group>
      {createIssueMutation.isError && (
        <Alert color="red">{t("common:toasts.genericError")}</Alert>
      )}
    </Stack>
  );
};

interface LinkIssueModalProps {
  repos: Repo[];
  ticketID: number;
  onSuccess: () => void;
  onClose: () => void;
}

const LinkIssueModal = (props: LinkIssueModalProps) => {
  const [repo, setRepo] = useState(props.repos[0].Name);
  const [issueID, setIssueID] = useState("");

  const linkIssueMutation = useLinkIssue(() => {
    props.onSuccess();
    props.onClose();
  });

  const linkIssue = () => {
    linkIssueMutation.mutate({
      TicketID: props.ticketID,
      Repo: repo,
      IssueID: parseInt(issueID),
    });
  };

  return (
    <Stack>
      <Select
        label="Repo"
        data={props.repos.map((x) => x.Name)}
        value={repo}
        onChange={(event: any) => setRepo(event.target.value)}
      ></Select>
      <TextInput
        label="Issue ID"
        value={issueID}
        placeholder="Ange issue #"
        autoFocus
        onChange={(event: any) => {
          setIssueID(event.target.value);
        }}
      ></TextInput>
      <Group position="right">
        <Button variant="light" onClick={props.onClose}>
          Avbryt
        </Button>
        <Button onClick={linkIssue} loading={linkIssueMutation.isLoading}>
          Länka
        </Button>
      </Group>
      {linkIssueMutation.isError && (
        <Alert color="red">Något gick fel. Försök igen!</Alert>
      )}
    </Stack>
  );
};

interface IssueLinkProps {
  ticketID: number;
  issue: Issue;
  onUnlinkIssue: () => void;
}

const IssueLink = (props: IssueLinkProps) => {
  const modals = useModals();

  const unlinkIssueMutation = useUnlinkIssue(() => {
    props.onUnlinkIssue();
  });

  const unlinkIssue = () => {
    unlinkIssueMutation.reset();
    modals.openConfirmModal({
      title: "Ta bort länk till issue",
      children: (
        <Text>
          {"Kopplingen mellan ärendet och issuet kommer att tas bort " +
            "(issuet kommer att finnas kvar i GitHub). " +
            "Vill du fortsätta?"}
        </Text>
      ),
      labels: { confirm: "Fortsätt", cancel: "Avbryt" },
      onConfirm: () => {
        unlinkIssueMutation.mutate({
          TicketID: props.ticketID,
          IssueID: props.issue.ID,
        });
      },
    });
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Tooltip label={props.issue.Title} position="top-start">
          <Anchor href={props.issue.URL} target="_blank" rel="noreferrer">
            {`Gå till issue #${props.issue.ID}`}
          </Anchor>
        </Tooltip>
        <Tooltip label="Ta bort länk">
          <ActionIcon
            variant="subtle"
            onClick={unlinkIssue}
            disabled={unlinkIssueMutation.isLoading}
          >
            <IconUnlink size={18} />
          </ActionIcon>
        </Tooltip>
      </Flex>
      {unlinkIssueMutation.isError && (
        <Alert color="red">Något gick fel!</Alert>
      )}
    </>
  );
};

interface SideBarGithubProps {
  ticket?: Ticket;
  onTicketUpdated: () => void;
}

export const SideBarGithub = (props: SideBarGithubProps) => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState("");
  const modals = useModals();

  const listReposQuery = useListRepos({
    Enabled: !!props.ticket,
    OnSuccess: () => {
      setLoading(false);
      setAuthorized(true);
      setError("");
    },
    OnError: (err: ErrorResponse) => {
      setLoading(false);
      if (err.Response?.Reason === "oauth.unauthorized") {
        setAuthorized(false);
        setError("");
      } else {
        setError("Något fel uppstod när inloggning skulle verifieras");
      }
    },
  });

  const connectToGithub = useGithubAuthMutation();
  const url: string | undefined = connectToGithub.data?.URL;
  if (!!url) window.open(url, "_self");

  const revokeGithubAuth = useRevokeGithubAuth(() => {
    setAuthorized(false);
  });

  const createIssueClicked = () => {
    if (props.ticket) {
      const modalId = modals.openModal({
        title: "Skapa issue",
        centered: true,
        closeOnClickOutside: false,
        children: (
          <CreateIssueModal
            repos={listReposQuery.data?.Repos ?? []}
            ticket={props.ticket}
            onSuccess={() => props.onTicketUpdated()}
            onClose={() => modals.closeModal(modalId)}
          ></CreateIssueModal>
        ),
      });
    }
  };

  const linkIssueClicked = () => {
    if (props.ticket) {
      const modalId = modals.openModal({
        title: "Länka befintligt issue",
        centered: true,
        closeOnClickOutside: false,
        children: (
          <LinkIssueModal
            repos={listReposQuery.data?.Repos ?? []}
            ticketID={props.ticket.ID}
            onClose={() => modals.closeModal(modalId)}
            onSuccess={() => props.onTicketUpdated()}
          />
        ),
      });
    }
  };

  const githubSection = authorized ? (
    <Stack spacing="xs">
      {listReposQuery.data?.Repos?.length ? (
        <>
          {props.ticket?.Issues?.length && (
            <Box mb="md">
              <Text
                sx={(theme) => ({ color: theme.colors.bluegray[5] })}
                size="sm"
                weight="bold"
              >
                Länkade issues
              </Text>
              <Stack spacing="xs">
                {props.ticket.Issues.map((issue) => (
                  <IssueLink
                    key={issue.ID}
                    ticketID={props.ticket?.ID ?? 0}
                    issue={issue}
                    onUnlinkIssue={props.onTicketUpdated}
                  />
                ))}
              </Stack>
            </Box>
          )}
          <Button
            variant="light"
            onClick={createIssueClicked}
            disabled={revokeGithubAuth.isLoading}
            leftIcon={<IconFilePlus size={16} />}
          >
            Skapa issue
          </Button>
          <Button
            variant="light"
            onClick={linkIssueClicked}
            disabled={revokeGithubAuth.isLoading}
            leftIcon={<IconLink size={16} />}
          >
            Länka issue
          </Button>
        </>
      ) : (
        <Alert>Behörighet till repos saknas</Alert>
      )}
      <Button
        color="red"
        variant="light"
        onClick={() => revokeGithubAuth.mutate()}
        loading={revokeGithubAuth.isLoading}
        leftIcon={<IconLogout size={16} />}
      >
        Logga ut från Github
      </Button>
    </Stack>
  ) : (
    <Button
      onClick={() => connectToGithub.mutate()}
      loading={connectToGithub.isLoading}
      leftIcon={<img alt="GitHub" src={githubIcon} width={18} height={18} />}
      sx={(theme) => ({
        backgroundColor: "#333",
        color: "#fff",
        "&:hover": {
          backgroundColor: theme.fn.darken("#333", 0.1),
        },
      })}
    >
      Logga in på GitHub
    </Button>
  );

  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack spacing="xs">
        <Title order={5}>Github</Title>
        {!props.ticket || loading ? (
          <Skeleton height={12} width={180} mt={6} radius="sm" />
        ) : error ? (
          <Alert color="red">{error}</Alert>
        ) : (
          githubSection
        )}
      </Stack>
    </Paper>
  );
};

interface SideBarOtherProps {
  ticketID?: number;
  children?: ReactNode;
}

export const SideBarOther = (props: SideBarOtherProps) => {
  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack spacing="xs">
        <Title order={5}>Övrigt</Title>
        {props.children}
        {props.ticketID && (
          <Button<"a">
            component="a"
            href={getFreshDeskUrl(props.ticketID)}
            target="_blank"
            rel="noreferrer"
            variant="light"
            leftIcon={<IconExternalLink size={15} />}
          >
            Öppna i Freshdesk
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

interface SideBarExerciseDetailsProps {
  course?: Course;
  exercise?: SyllabusExercise;
  lesson?: SyllabusLesson;
}

export const SideBarExerciseDetails = (props: SideBarExerciseDetailsProps) => {
  if (!props.course || !props.lesson || !props.exercise) return null;

  return (
    <Paper
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.colors.bluegray[0],
      })}
    >
      <Stack>
        <Title order={5}>Övningsdetaljer</Title>

        <CourseCard course={props.course} />
        <Stack spacing={4}>
          <Text size="sm" weight="bold">
            {props.lesson.Name !== ""
              ? props.lesson.Name
              : `Lektion ${props.lesson.LessonIndex}`}
            , övning {props.exercise.ExerciseIndex}
          </Text>
          <Text>{props.exercise.Name}</Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

interface MessageInputProps {
  ticket?: Ticket;
  onMessageAdded: () => void;
}

export const MessageInput = (props: MessageInputProps) => {
  const [messageType, setMessageType] = useState<string>();

  const createMessageMutation = useCreateMessage(() => {
    setMessageType("");
    props.onMessageAdded();
  });

  if (!props.ticket) {
    return <></>;
  }

  const replyDisabled = !props.ticket.Emails?.length;
  if (!messageType) {
    return (
      <>
        <Group position="right">
          <Button
            size="xs"
            variant="outline"
            leftIcon={<IconNotes size={20} />}
            onClick={() => setMessageType("note")}
            disabled={replyDisabled}
          >
            Skapa anteckning
          </Button>
          <Button
            size="xs"
            variant="outline"
            leftIcon={<IconCornerUpLeft size={20} />}
            onClick={() => setMessageType("reply")}
            disabled={replyDisabled}
          >
            Svara
          </Button>
        </Group>
      </>
    );
  }

  const onMessageCancel = () => {
    setMessageType("");
  };

  const onMessageSubmit = (val: any) => {
    const message = `<div>${val.message}</div>`; //editor html has no root, add one.
    props.ticket &&
      createMessageMutation.mutate({
        TicketID: props.ticket.ID,
        Type: val.type,
        Message: message,
        CcEmails: val.ccEmails,
      });
  };

  return messageType === "reply" ? (
    <ReplyEditor
      ticket={props.ticket}
      loading={createMessageMutation.isLoading}
      error={createMessageMutation.isError}
      onCancel={onMessageCancel}
      onSubmit={onMessageSubmit}
    />
  ) : (
    <NoteEditor
      loading={createMessageMutation.isLoading}
      error={createMessageMutation.isError}
      onCancel={onMessageCancel}
      onSubmit={onMessageSubmit}
    />
  );
};

interface NoteEditorProps {
  loading: boolean;
  error: boolean;
  onCancel: () => void;
  onSubmit: (note: any) => void;
}

const NoteEditor = (props: NoteEditorProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [note, setNote] = useState<string>();
  const topBar = (
    <RichTextEditor.Toolbar style={{ background: theme.colors.bluegray[2] }}>
      <Title order={5}>Ny anteckning</Title>
    </RichTextEditor.Toolbar>
  );

  const onNoteUpdate = (val: string) => {
    setNote(val);
  };

  const onSubmit = () => {
    props.onSubmit({ type: MessageTypeNotePrivate, message: note });
  };

  return (
    <Stack spacing="xs">
      <TextEditor
        initialContent={note}
        placeHolder="Skriv din anteckning..."
        topBar={topBar}
        onUpdate={onNoteUpdate}
      />
      <Group position="right">
        {props.error && (
          <Alert color="red">{t("common:toasts.genericError")}</Alert>
        )}
        <Button
          variant="outline"
          onClick={props.onCancel}
          disabled={props.loading}
        >
          Avbryt
        </Button>
        <Button onClick={onSubmit} disabled={!note} loading={props.loading}>
          Spara
        </Button>
      </Group>
    </Stack>
  );
};

interface ReplyEditorProps {
  ticket: Ticket;
  loading: boolean;
  error: boolean;
  onCancel: () => void;
  onSubmit: (reply: any) => void;
}

const ReplyEditor = (props: ReplyEditorProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const userName =
    props.ticket.User.FirstName + " " + props.ticket.User.LastName;

  const primaryEmail = findReplyEmail(props.ticket.Emails);

  const previousConversation = buildResponsePreviousThread(props.ticket);
  const [reply, setReply] = useState<string>(`
          <div>
            <p>Hej ${userName},</p>
            <p/>
            <p>Tack för ditt meddelande.</p>
            <p/>
            <p/>
            <p>Med vänlig hälsning,</p>
            <p>Skillster support</p>
            <p></p>
            ${previousConversation}
          </div>`);
  const [ccEmails, setCcEmails] = useState(
    props.ticket.Emails.filter((x) => x.Value !== primaryEmail.Value).map(
      (x) => x.Value
    )
  );
  const topBar = (
    <>
      <RichTextEditor.Toolbar style={{ background: theme.colors.bluegray[2] }}>
        <Title order={5}>Nytt svar</Title>
        <Flex justify="flex-end" style={{ flexGrow: 1 }}>
          <Group>
            <Text>Skickas till:</Text>
            <Text style={{ fontWeight: "bold" }}>{primaryEmail.Value}</Text>
          </Group>
        </Flex>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Toolbar>
        <Group>
          <Title order={5}>Cc: </Title>
          <CcInput
            ccEmails={ccEmails}
            onCcUpdate={(val: string[]) => setCcEmails(val)}
          ></CcInput>
        </Group>
      </RichTextEditor.Toolbar>
    </>
  );

  const onNoteUpdate = (val: string) => {
    setReply(val);
  };

  const onSubmit = () => {
    props.onSubmit({
      type: MessageTypeReply,
      message: reply,
      ccEmails: ccEmails,
    });
  };

  return (
    <Stack spacing="xs">
      <TextEditor
        initialContent={reply}
        topBar={topBar}
        onUpdate={onNoteUpdate}
      />
      <Group position="right">
        {props.error && (
          <Alert color="red">{t("common:toasts.genericError")}</Alert>
        )}
        <Button
          variant="outline"
          onClick={props.onCancel}
          disabled={props.loading}
        >
          Avbryt
        </Button>
        <Button onClick={onSubmit} loading={props.loading}>
          Skicka
        </Button>
      </Group>
    </Stack>
  );
};

interface CcInputProps {
  ccEmails: string[];
  onCcUpdate: (val: string[]) => void;
}

const CcInput = (props: CcInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const onChangeHandler = (event: any) => {
    const val = event.target.value;
    if (
      val &&
      props.ccEmails.some((x) => x.toLowerCase() === val.toLowerCase())
    ) {
      setError("duplicate");
    } else {
      setError("");
    }
    setInputValue(val);
  };

  const onKeyDownHandler = (event: any) => {
    if (event.key === "Enter" && !error) {
      if (
        inputValue &&
        !props.ccEmails.some((x) => x.toLowerCase() === inputValue)
      ) {
        props.onCcUpdate([...props.ccEmails, inputValue]);
        setInputValue("");
      }
    }
  };

  const onRemoveEmail = (val: string) => {
    props.onCcUpdate(props.ccEmails.filter((x) => x !== val));
  };

  return (
    <Group spacing="xs">
      {props.ccEmails.map((x) => (
        <Badge
          key={x}
          radius="sm"
          sx={(theme) => ({
            color: theme.colors.bluegray[5],
            textTransform: "lowercase",
          })}
        >
          <Flex align="center">
            {x}
            <IconX
              size={12}
              style={{ marginLeft: 10, cursor: "pointer" }}
              onClick={() => onRemoveEmail(x)}
            />
          </Flex>
        </Badge>
      ))}
      <TextInput
        error={!!error}
        placeholder="Lägg till email..."
        value={inputValue}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        sx={{
          width: 200,
          input: {
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent !important",
          },
        }}
      />
    </Group>
  );
};
